<template>
  <AlignCenterContainer session-expired>
    <div>
      <FontIcon name="timer-off" />
      <h3>Session Expired</h3>
      <p>Your session has expired. Please try again.</p>
    </div>
  </AlignCenterContainer>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import AlignCenterContainer from '@/views/components/pages/promotion/AlingCenterContainer.vue';

export default {
  name: 'SessionExpired',
  components: { AlignCenterContainer, FontIcon },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[session-expired] { .fs(16); .h(100vh);
  [font-icon] { .pb(0.625em); .fs(5em); .c(@c-dimGrey); }
  h3 { .medium(); .fs(2em); .c(@c-veryLightGrey); }
  p { .mt(0.5em); .c(@c-mediumGrey); }
}
</style>
